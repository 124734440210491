import axio from "axios";

export const baseurl = axio.create({
    // baseURL: "http://127.0.0.1:8000/api",
    baseURL: "http://162.0.237.160:4520/api",

});

baseurl.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    if (token != null) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
})

