
import React, { useState } from 'react';
import "./user.css"

import {
    Card,
    message,
    Steps,
    Col,
    Row,
    Spin,
    Alert,
    Layout
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import UserForm from './UserForm';
import CreateCondominium from '../Condominium/CreateCondominium';
import { baseurl } from '../../lib/settings';
import Header from '../../components/header/Header';

const { Step } = Steps;
const { Footer } = Layout

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

const CreateUser = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [current, setCurrent] = useState(0);
    const [userFormValues, setUserFormValues] = useState();
    const [condominiumFormValues, setCondominiumFormValues] = useState();
    const [getData, setGetData] = useState(false)
    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const getUserValue = (values) => {
        setUserFormValues(values);
    };

    const submit = (value) => {
        setIsLoading(true)

        const data = {
            user_name: userFormValues['user_name'],
            contact: "258" + userFormValues['contact'],
            bairro: userFormValues['bairro'],
            cidade: userFormValues['cidade'],
            gender: userFormValues['gender'],
            administrator: true,
            is_staff: true,
            is_active: false,
            description: value.cond.description,
            cond_number: value.cond.cond_number,
            city: value.cond.city,
            district: value.cond.district,
            block: value.cond.block,
            amount: value.cond.amount
            // amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value.cond.amount)
        }
        console.log("Dados", data)

        baseurl.post("v1/create_condominium_from/web/", data).then((resp) => {
            if (resp.status == 201) {
                setGetData(true)
                setCurrent(current + 1);
                setIsLoading(false)
            }

        }).catch((error) => {
            message.error("Algo correu mal.")
            setIsLoading(false)
        })
    }

    const steps = [
        {
            title: 'Dados do morador',
            content: <UserForm setUserformValues={setUserFormValues} setCurrent={setCurrent} current={current} userValueFunction={getUserValue} />,
        },
        {
            title: 'Dados do condomínio',
            content: <CreateCondominium setCondominiumFormValues={setCondominiumFormValues} setCurrent={setCurrent} current={current} submitFuction={submit} loading={isLoading} />,
        },
        {
            title: 'Fim',
            content: <Alert message="Condomínio criado com sucesso!" type="success" showIcon />,
        },
    ];


    return (
        <>
            <Row
                type="flex"
                style={{ alignItems: "center" }}
                justify="center"
                block>
                <Col xs={21} xl={18}>
                    {/* <>
                        <div className='isLoading'>
                            <Spin indicator={antIcon} />
                        </div>
                    </> */}
                    <>
                        <div className='main-step'>
                            <Card bordered={false} className="criclebox h-full" hoverable style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} >
                                <Steps current={current}>
                                    {steps.map((item) => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <br />
                                <br />
                                <div className="steps-content">{steps[current].content}</div>
                                <div className="steps-action">
                                </div>
                            </Card>
                        </div>
                    </>
                </Col>
            </Row>
          

        </>
    );
};

export default CreateUser;
