import 'antd/dist/antd.css'
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import UserForm from "./pages/User/UserForm";
import CreateUser from "./pages/User/CreateUser";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ResidentRegistration from './pages/Resident/residentRegistration';
import CreateCondominium from './pages/Condominium/CreateCondominium';


function App() {

  return (

    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={< CreateUser />} />
          <Route path="/cond" element={< CreateCondominium />} />
          <Route path="/resident/:urlCode" element={<ResidentRegistration />} />
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
  

    </div>

  );
}

export default App;
