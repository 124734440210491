
import React from 'react';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Typography,
    Col,
    Row,
    Divider,
    Select,
} from 'antd';
import { baseurl } from '../../lib/settings';
import Header from '../../components/header/Header';
import { useState } from 'react';
import { ErrorMessage } from '../../components/messages/genericMessages';

const { Option } = Select;
const { Title } = Typography

// const layout = {
//     labelCol: {
//         span: 8,
//     },
//     wrapperCol: {
//         span: 16,
//     },
// };


const validateMessages = {
    required: 'Este campo é obrigatório!',
    types: {
        email: 'Este email não é valido!',
        number: 'Este número não é válido!',
    },
    number: {
        range: '${label} Deve estar entre ${min} e ${max}',
    },
};


const UserForm = ({ setUserFormValues, setCurrent, current, userValueFunction }) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [noUser, setNoUser] = useState(false);
    const [isError, setIsError] = useState(false);
    const [contact, setContatct] = useState('');
    const [message, setMessage] = useState("Desculpe ocorreu um erro inesperado");

    const userVerify = (values) => {
        setIsLoading(true)
        setContatct(values.user.contact)
        baseurl.post("v1/user_contact_verify/", { "contact": "258" + values.user.contact }).then((resp) => {
            if (resp.data['is_user']) {
                setIsLoading(false)
                userValueFunction({contact:values.user.contact,});
                setCurrent(current + 1);
            } else {
                setNoUser(true)
                setIsLoading(false)
            }
        }).catch((error) => {
            console.log(error)
            if (error.response.status === 400) {
                setMessage(`${error.response.data}`)
                setIsError(true)
            } else {
                setIsError(true)
            }
            setIsLoading(false)
        })
    }

    const onFinish = (values) => {
        setIsLoading(true)
        setIsLoading(false)
            
        userValueFunction({
            user_name:values.user.user_name,
            contact: contact,
            bairro: values.user.bairro,
            cidade: values.user.cidade,
            gender: values.user.gender,
        });
        setCurrent(current + 1);
    };


    return (
        <>


            {!noUser ?
                <Row type="flex" style={{ alignItems: "center" }} justify="center" block>
                    <Col xs={21} xl={18}>
                        {isError && <ErrorMessage message={message} />}
                        <br />
                        <Form
                            form={form}
                            name="basic"
                            layout="vertical"
                            onFinish={userVerify}
                            validateMessages={validateMessages}
                        >
                            <Row gutter={[24, 0]}>
                            </Row>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">

                                    <Form.Item
                                        name={['user', 'contact']}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Telefone' prefix="+258" disabled={isLoading} />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Divider />
                            <Button isLoading={isLoading} style={{ float: 'left' }} type="primary" htmlType="submit" disabled={isLoading} block>
                                Submeter
                            </Button>
                        </Form>
                    </Col>
                </Row>
                : <Row type="flex" style={{ alignItems: "center" }} justify="center" block>
                    <Col xs={21} xl={18}>
                        {isError && <ErrorMessage message={message} />}
                        <br />
                        <Form
                            form={form}
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Row gutter={[24, 0]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                                    <Form.Item
                                        name={['user', 'user_name']}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Nome' disabled={isLoading} />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                                    <Form.Item
                                        name={['user', 'gender']}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Genero" disabled={isLoading} allowClear>
                                            <Option value="M">Masculino</Option>
                                            <Option value="F">Feminino</Option>
                                            <Option value="Outro">Outro</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Button isLoading={isLoading} style={{ float: 'left' }} type="primary" htmlType="submit" disabled={isLoading} block>
                                Submeter
                            </Button>
                        </Form>
                    </Col>
                </Row>}
        </>
    );
};

export default UserForm;