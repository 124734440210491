import React from 'react'
import {
    Button,
    Form,
    Input,
    InputNumber,
    Typography,
    Col,
    Row,
    Divider
} from 'antd';
const { Title } = Typography;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const validateMessages = {
    required: 'Este campo é obrigatório',
    types: {
        email: 'Este email não é válido!',
        number: 'Este número não é válido!',
    },
    number: {
        range: '${label} Deve estar entre ${min} e ${max}',
    },
};

const CreateCondominium = ({ setCondominiumFormValues, setCurrent, current, submitFuction, loading }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        submitFuction(values);
    };

    function prev() {
        setCurrent(current - 1)
    }

    return (

        <>

            <Row type="flex" style={{ alignItems: "center" }} justify="center" block>
                <Col xs={21} xl={18}>
                    <Form
                        form={form}
                        // id={"roomID"}
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                    >

                        <Form.Item
                            name={['cond', 'description']}

                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder='Nome do condomínio' disabled={loading} />
                        </Form.Item>

                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">

                                <Form.Item
                                    name={['cond', 'cond_number']}

                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        min={1}
                                        max={999999999999999}
                                        placeholder='Número do condomínio' disabled={loading} />
                                </Form.Item>
                            </Col >
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">

                                <Form.Item
                                    name={['cond', 'city']}
                                >
                                    <Input placeholder='Cidade' disabled={loading} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">

                                <Form.Item
                                    name={['cond', 'district']}

                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder='Bairro' disabled={loading} />
                                </Form.Item>
                            </Col >
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">

                                <Form.Item
                                    name={['cond', 'block']}

                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder='Quarteirão' disabled={loading} />
                                </Form.Item>
                            </Col>


                        </Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                            <Form.Item
                                name={['cond', 'amount']}

                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={100000} placeholder='Taxa de condomínio' style={{ width: '100%' }} disabled={loading} />
                            </Form.Item>
                        </Col>
                        <Divider />

                        <Button htmlType="click" onClick={prev} disabled={loading}>
                            Voltar
                        </Button>

                        <Button loading={loading} style={{ float: 'right' }} type="primary" htmlType="submit" >
                            Submeter
                        </Button> &nbsp;

                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default CreateCondominium
