import React, { useState } from 'react';
import { useLocation } from 'react-router';
import "./resident.css"

import {
    Card,
    Steps,
    Col,
    Row,
    Alert,
} from 'antd';
import ResidentForm from './forms/resident';
import { useEffect } from 'react';
import { baseurl } from '../../lib/settings';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '../../components/messages/genericMessages';

const { Step } = Steps;

export default function ResidentRegistration() {
    const [isLoading, setIsLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [condominium, setCondominium] = useState({});
    const [finalmessage, setFinalMessage] = useState(``);
    const [message, setMessage] = useState("Desculpe ocorreu um erro inesperado");
    const [isError, setIsError] = useState(false);
    const location = useParams();

    useEffect(()=>{
        setIsLoading(true)
        let code = {urlCode:location.urlCode}
        baseurl.get('v1/url_control/', {params:code}).then((resp)=>{
            setCondominium(resp.data)
            setFinalMessage(`Parabes agora voce faz parte do condominio ${resp.data['cond_resident'][`condominium`]['description']}`)
        }).catch((error) => {
            console.log(error)
            if(error.response.status == 400){
                setMessage(`${error.response.data}`)
                setIsError(true)
            }else{
                setIsError(true)
            }
            setIsLoading(false)
        })
    },[])

    const steps = [
        {
            title: 'Dados do morador',
            content: <ResidentForm setCurrent={setCurrent} current={current} condID={condominium}/>,
        },
        {
            title: 'Fim',
            content: <Alert message={finalmessage} type="success" showIcon />,
        },
    ];
    return (
        <>
            <Row
                type="flex"
                style={{ alignItems: "center" }}
                justify="center"
                block>
                <Col xs={21} xl={18}>
                    {/* <>
                        <div className='isLoading'>
                            <Spin indicator={antIcon} />
                        </div>
                    </> */}
                    <>
                        <div className='main-step'>
                            <Card bordered={false} className="criclebox h-full" hoverable style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} >
                                <Steps current={current}>
                                {isError ? <ErrorMessage message={message}/> : <>
                                {steps.map((item) => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </>}
                                    
                                </Steps>
                                <br />
                                <br />
                                <div className="steps-content">{steps[current].content}</div>
                                <div className="steps-action">
                                </div>
                            </Card>
                        </div>
                    </>
                </Col>
            </Row>
        </>
    )
}