import React from 'react'
import { Layout } from 'antd';
import "../css/footer.css"

function Footer() {

    const { Footer } = Layout;

    var date = new Date()

    return (
        // <div className='footer'>
        //     All rights reserved &copy; SPERANZA {date.getFullYear()} <br /><br />
        // </div>
        <Layout>
            <Footer style={{ textAlign: 'center' }}>All rights reserved &copy; SPERANZA {date.getFullYear()}</Footer>
        </Layout>

    )
}

export default Footer