import React from 'react'
import { Breadcrumb, Layout, Menu } from 'antd';
import { Button, Descriptions, PageHeader } from 'antd';







function Header() {
    const { Header, Content, Footer } = Layout;

    return (
        <div className="site-page-header-ghost-wrapper">
            <PageHeader
                style={{ backgroundColor: "#f3f4f5" }}
                ghost={false}
                // onBack={() => window.history.back()}
                title="Condomínio"
                // subTitle="This is a subtitle"
                // extra={[
                //     <Button key="3">Login</Button>,
                //     <Button key="2">Sign up</Button>,
                // ]}
            >
            </PageHeader>
            <br />
        </div>
    )
}

export default Header
